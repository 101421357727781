@use "@/styles/utils";
@use "@/styles/breakpoints";

.story {
  .section {
    &__content {
      padding: 30px 132px 55px 58px;
      gap: 33px;
    }

    &__text {
      @include utils.textCorners(left top, left bottom);
    }

    @include breakpoints.respond(m) {
      &__content {
        padding: 20px 20px 25px 31px;
      }
    }

    @include breakpoints.respond(s) {
      &__content {
        padding: 20px;
      }
    }

    @include breakpoints.respond(xs) {
      &__content {
        padding: 58px 24px;
      }

      &__image {
        width: 80dvh;
      }

      &__text {
        @include utils.textCorners(left top, right top, right bottom, left bottom);
      }
    }
  }
}
