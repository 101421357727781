@use "@/styles/utils";
@use "@/styles/colors";
@use "@/styles/fonts";
@use "@/styles/breakpoints";
@use "~swiper/css";
@use "~swiper/css/scrollbar";
@use "~swiper/css/navigation";
@use "~swiper/css/grid";

.swiper {
  max-width: 100%;

  &-button-prev,
  &-button-next {
    width: 0;
    height: 0;
    margin-top: auto;

    &:after {
      font-size: 1.5dvw;
      @include breakpoints.respond(xs) {
        font-size: 20px;
      }
    }
  }

  &-button-prev {
    left: 20px;
    @include breakpoints.respond(xs) {
      left: var(--swiper-navigation-sides-offset, 10px);
    }
  }

  &-button-next {
    right: 20px;
    @include breakpoints.respond(xs) {
      right: var(--swiper-navigation-sides-offset, 10px);
    }
  }
}

.main {
  transition: opacity 0.5s;

  .section {
    opacity: 0;
    transition: opacity 0.5s ease-in;

    &.swiper-slide-active {
      opacity: 1;

      .specifictaions__item-list {
        max-height: 100%;
        transition: max-height 1s ease-in;
      }

      .divider {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100dvw;
  height: 100dvh;
  font-family: Grifter, Helvetica, sans-serif;
  overflow: hidden;
  color: colors.$intense-white;
  -webkit-user-select: none;
  user-select: none;
  @include utils.setBackground;

  &.modal-open .main {
    opacity: 0;
    pointer-events: none;
  }
}

.section {
  box-sizing: border-box;
  display: flex !important;
  flex-direction: column;

  .section {
    opacity: 1;
    flex: 1;
  }

  &__text {
    @include fonts.section-text;
  }
}
