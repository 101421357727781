@use "@/styles/utils";
@use "@/styles/breakpoints";

.oduwan {
  .section {
    flex: 1;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @include breakpoints.respond(s, xs) {
        align-items: center;
      }
      height: 100%;
      min-height: 0;
    }

    .row {
      min-height: 0;
    }
  }
}
