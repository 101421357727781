@use "./breakpoints";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

$section-title-font-size: 5dvw;
$section-text-font-size: 1.1dvw;

@mixin section-title() {
  font-family: "Hanson", sans-serif;
  font-size: $section-title-font-size;
  line-height: $section-title-font-size;
}

@mixin section-text() {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: $section-text-font-size;
  line-height: 1.5dvw;

  @include breakpoints.respond(m) {
    padding: 15px;
  }
  @include breakpoints.respond(s) {
    font-size: 1.7dvw;
    line-height: 2.5dvw;
  }
  @include breakpoints.respond(xs) {
    padding: 10px 15px;
    font-size: 12px;
    line-height: 16px;
  }
}

@mixin input-text() {
  font-family: "Manrope", sans-serif;
  font-size: 1.3dvw;
  font-weight: 700;
  line-height: 32.78px;
  letter-spacing: 0.05em;

  @include breakpoints.respond(m) {
    font-size: 17px;
  }
}

@font-face {
  font-family: Grifter;
  src: url("../assets/fonts/grifter-bold.woff2") format("woff2"),
    url("../assets/fonts/grifter-bold.woff") format("woff"),
    url("../assets/fonts/grifter-bold.ttf") format("truetype");
}

@font-face {
  font-family: Hanson;
  src: url("../assets/fonts/hanson-bold.woff2") format("woff2"),
    url("../assets/fonts/hanson-bold.woff") format("woff"),
    url("../assets/fonts/hanson-bold.ttf") format("truetype");
}
