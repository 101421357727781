@use "@/styles/colors";
@use "@/styles/breakpoints";

.footer {
  &[data-theme="dark"] {
    color: colors.$intense-black;
  }
  padding: 0.4% 11%;
  border-top: 1px solid;
  text-transform: uppercase;
  font-family: "Manrope", sans-serif;
  font-size: 0.8dvw;
  font-weight: 800;
  line-height: normal;
  z-index: 1;
  transition: 0.5s;
  @include breakpoints.respond(m) {
    padding: 4px 163px;
    font-size: 12px;
    line-height: 16.39px;
  }
  @include breakpoints.respond(s) {
    font-size: 1dvw;
    line-height: 1.6dvw;
    padding: 5px 0;
  }
  @include breakpoints.respond(xs) {
    padding: 5px 0;
    font-size: 12px;
    line-height: 16px;
  }

  &__text-container {
    display: flex;
    justify-content: space-between;
    @include breakpoints.respond(s) {
      justify-content: space-around;
    }
    @include breakpoints.respond(xs) {
      animation: running-text 15s linear infinite;
    }
  }

  &__text {
    @include breakpoints.respond(xs) {
      flex: 1 0 100%;
      text-align: center;
    }
  }
}

@keyframes running-text {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-300%);
    -webkit-transform: translateX(-300%);
    transform: translateX(-300%);
  }
}
