@use "@/styles/utils";
@use "@/styles/breakpoints";

.process {
  .section {
    &__content {
      position: relative;
      height: 100%;
      display: flex;
      flex: 1 1;
      gap: 60px;
      @include utils.textCorners(left top, right bottom);

      &-wrapper {
        flex: 1;
        padding: 14px 15% 13px 15%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &__text {
      flex: 1;

      &:last-child {
        align-self: flex-end;
      }
    }

    &__image {
      align-self: center;
      @include utils.imageSize(100%, 49dvh);
    }

    @include breakpoints.respond(m) {
      &__content {
        gap: 42px;

        &-wrapper {
          padding: 23px 7dvw 17px;
        }
      }
    }

    @include breakpoints.respond(s) {
      &__content {
        gap: 20px;

        &-wrapper {
          padding: 20px;
        }
      }

      &__image {
        max-width: 30dvw;
      }
    }

    @include breakpoints.respond(xs) {
      &__content {
        flex-direction: column;
        gap: 15px;

        &::before {
          display: none;
        }

        &-wrapper {
          padding: 14px 5% 13px;
        }
      }

      &__text {
        &:first-child {
          @include utils.textCorners(left top, right top);
          @include utils.dividerLine(50%, 20px);
        }

        &:last-child {
          @include utils.textCorners(left bottom, right bottom);
        }
      }

      &__image {
        order: 1;
        width: 100%;
        max-height: 30dvh;
        object-fit: cover;
      }
    }
  }
}
