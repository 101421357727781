@use "@/styles/colors";

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/images/backgrounds/contact.jpg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.7s ease-out;

  &__image {
    width: 50%;
    height: 100%;
  }
}
