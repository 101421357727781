@use "@/styles/utils";
@use "@/styles/colors";
@use "@/styles/breakpoints";

.bikes-customisation {
  .section {
    &__content {
      padding: 2dvh 108px 2dvh 65px;
      height: 100%;
      display: flex;
      flex: 1 1;
      gap: 88px;
      min-height: 0;

      &-wrapper {
        flex: 2 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 42px;

        .section__text {
          @include utils.textCorners(left top, left bottom, right top, right bottom);
        }
      }
    }

    &__image {
      width: 50dvh;
      z-index: 1;
    }
  }

  .gallery-entry {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3 1;

    &:hover {
      .gallery-entry__background {
        filter: blur(0px);
      }
    }

    &__background {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 8.5dvw;
      align-items: stretch;
      justify-items: stretch;
      gap: 1dvw;
      opacity: 0.3;
      transition: 0.5s;
      filter: blur(3px);
    }

    &__caption {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 65px;

      .section__text {
        text-align: center;
      }

      .button {
        box-shadow: 2px 2px 12px transparentize(colors.$intense-white, 0.7);
      }
    }

    &__image {
      object-fit: cover;
    }
  }

  @include breakpoints.respond(m) {
    .section {
      &__content {
        padding: 3dvh 20px 3dvh 19px;
        gap: 60px;

        &-wrapper {
          gap: 3dvh;
        }
      }
    }

    .gallery-entry {
      &__background {
        grid-auto-rows: 14dvh;
      }
    }
  }

  @include breakpoints.respond(s) {
    .section {
      &__content {
        padding: 20px;
      }
    }

    .gallery-entry {
      &__background {
        grid-auto-rows: auto;
      }
    }
  }

  @include breakpoints.respond(xs) {
    .section {
      &__content {
        padding: 2dvh 18px 2dvh 15px;
        display: flex;
        flex-direction: column-reverse;
        gap: 2dvh;
      }

      &__image {
        width: 25dvh;
        position: absolute;
        top: 42%;
      }
    }

    .gallery-entry {
      min-height: 0;

      &__background {
        grid-auto-rows: 10dvh;
      }

      &__caption {
        gap: 10px;
      }

      .section__text {
        padding: 0;
      }
    }
  }
}
