@use "@/styles/colors";
@use "@/styles/buttons";
@use "@/styles/breakpoints";

html {
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
}

body,
ul,
p,
h2,
h3,
h4,
figure {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: colors.$intense-white;
}

button {
  padding: 0;
  color: colors.$intense-white;
  background: none;
  text-align: left;
  cursor: pointer;
}

h2,
h3 {
  text-align: center;
}

h2,
h3,
a {
  text-transform: uppercase;
}

img {
  max-width: 100%;
  min-height: 0;
  object-fit: contain;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.br {
  display: block;
  margin-bottom: 2dvh;

  @include breakpoints.respond(m, s, xs) {
    margin-bottom: 1.4dvh;
  }
}

.row {
  height: 100%;
  display: flex;
  flex: 1;
}

.mb {
  margin-bottom: 1.4dvw;
  @include breakpoints.respond(m) {
    margin-bottom: 18px;
  }
  @include breakpoints.respond(s) {
    margin-bottom: 10px;
  }
}

.mt {
  margin-top: 1.4dvw;
  @include breakpoints.respond(m) {
    margin-top: 18px;
  }
  @include breakpoints.respond(s) {
    margin-top: 10px;
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
