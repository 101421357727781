@use "@/styles/colors";
@use "@/styles/breakpoints";

:root {
  --swiper-navigation-color: colors.$intense-white;
}

.gallery {
  &-wrapper {
    position: relative;
    width: 85%;
    @include breakpoints.respond(s, m) {
      width: 75%;
    }
    @include breakpoints.respond(s) {
      width: 90%;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgb(255 255 255 / 20%);
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #0000001e;

    .tabs {
      position: relative;
      padding: 1dvw 20dvw 0.9dvw;
      display: flex;
      justify-content: center;
      gap: 3dvw;
      font-family: "Hanson", sans-serif;
      border-top: 1px solid;

      &__item {
        font-size: 1.5dvw;
        flex: 1;
        line-height: 1.5dvw;
        letter-spacing: 0.05em;
        text-align: center;
        text-transform: uppercase;
        transition: opacity 0.2s;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        @include breakpoints.respond(s, m) {
          padding: 16px 0 10px;
        }

        @include breakpoints.respond(s) {
          font-size: 1.7dvw;
          line-height: 2dvw;
        }
      }
    }
  }

  max-height: 60dvh;
  display: flex;
  gap: 55px;
  padding: 46px 95px 33px 208px;
  @include breakpoints.respond(s, m) {
    padding: 18px 70px 1dvh 97px;
  }

  &__slider {
    flex: 2 1 auto;
    max-width: 100%;
    max-height: 100%;

    .swiper {
      &-slide {
        display: flex;
        justify-content: center;
      }
    }

    .gallery__image {
      width: 90dvh;
      height: 30dvw;
      object-fit: contain;
      box-shadow: none;
    }

    &-thumbs {
      flex: 1 0 auto;
      max-width: 100%;

      .swiper-slide {
        transition: opacity 0.3s;
        &.swiper-slide-thumb-active {
          opacity: 0.6;
        }
      }

      .gallery__image {
        width: 100%;
        object-fit: cover;
        box-shadow: 0px 4px 4px 0px #00000066;
      }

      .swiper-scrollbar-drag {
        background: colors.$intense-white;
      }
    }
  }

  &__image {
    aspect-ratio: 3 / 2;
    max-height: 100%;
    border-radius: 5px;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
  }

  &__button {
    position: absolute;
    top: 10px;
    right: 12px;
    cursor: pointer;
    z-index: 2;

    svg {
      width: 1.2dvw;
      height: 1.2dvw;
      fill: currentColor;
    }

    @include breakpoints.respond(xs) {
      top: 5px;
      right: 8px;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  @include breakpoints.respond(xs) {
    padding: 5dvw 2dvw 2dvw;
    flex-direction: column;
    gap: 2dvh;

    &-wrapper {
      width: 90%;

      .tabs {
        padding: 0 10px;
        gap: 40px;
        overflow-x: scroll;
        justify-content: space-between;

        &__item {
          padding: 10px 0;
          font-size: 12px;
          line-height: 15px;
          flex: 1;
        }

        .progress {
          height: 4px;
        }
      }
    }

    &__slider {
      .gallery__image {
        height: auto;
      }

      &-thumbs {
        .swiper {
          &-wrapper {
            flex-direction: row !important;
          }

          &-slide {
            margin-top: 0 !important;
          }
        }

        .gallery__image {
          border-radius: 3px;
        }
      }
    }
  }
}
