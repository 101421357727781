@use "@/styles/colors";
@use "@/styles/fonts";
@use "@/styles/breakpoints";

.contact {
  color: colors.$intense-black;

  .section {
    &-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6dvh;
    }

    &__content {
      flex: 1;
      padding: 0 6dvw 0 6dvw;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__text {
      padding: 0;
      max-width: 50%;
      font-size: 1.3dvw;
      text-transform: none;

      .br {
        margin-bottom: 0;
      }

      svg {
        position: relative;
        right: 2px;
        bottom: 5px;
        width: 9px !important;
        height: 9px;
        cursor: pointer;
      }
    }

    &__link {
      color: colors.$intense-black;
      text-transform: none;
      text-decoration: underline;
    }
  }

  &__form {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 35px;

    &__row {
      flex: 1 1 100%;
    }

    &-item {
      margin-bottom: 0;

      &__input {
        box-sizing: border-box;
        padding: 1.3dvw;
        width: 100%;
        background-color: transparentize(colors.$intense-white, 0.5);
        border-radius: 5px;
        border: 1px solid colors.$intense-black;

        &,
        &::placeholder {
          color: colors.$intense-black;
          @include fonts.input-text;
        }
      }
    }

    &-button {
      box-sizing: inherit;
      height: inherit;
      opacity: 0.9;
    }

    &__toast {
      font-size: 20px;
    }
  }

  .divider {
    border-color: colors.$intense-black;
  }

  @include breakpoints.respond(m) {
    .section {
      &-wrapper {
        padding: 12px 109px 32px;
      }

      &__content {
        padding: 0 95px;
      }

      &__text {
        font-size: inherit;
        line-height: inherit;
      }
    }

    &__form {
      gap: 2dvh;

      &-item {
        &__input,
        &__input::placeholder {
          font-size: 1dvw;
          line-height: normal;
        }
      }

      &-button {
        padding: 16px;
        opacity: 0.9;
        box-shadow: none;
      }

      &__row {
        row-gap: 3dvh !important;
      }
    }
  }

  @include breakpoints.respond(s) {
    .section {
      &-wrapper {
        padding: 70px !important;
        width: 70%;
      }
    }
  }

  @include breakpoints.respond(xs, s) {
    .section {
      &-wrapper {
        padding: 0;
      }

      @include breakpoints.respond(xs) {
        &__content {
          padding: 20px;
        }
      }

      &__text {
        font-size: 12px;
        line-height: 20px;
        max-width: 100%;
      }
    }

    &__form {
      width: 100%;
      gap: 24px;

      &-item {
        &__input,
        &__input::placeholder {
          font-size: 16px;
          line-height: normal;
          border-radius: 3px;
        }
      }
    }
  }
}
