@use "@/styles/colors";
@use "@/styles/breakpoints";

.progress {
  position: absolute;
  height: 0.7dvh;
  @include breakpoints.respond(s) {
    height: 0.4dvw;
  }
  background: colors.$intense-white;
  transition: left 0.5s;

  &--top {
    top: 0;
  }

  &--bottom {
    bottom: 0;
  }
}
