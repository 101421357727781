$l: 1920px;
$m: 1366px;
$s: 1145px;
$xs: 500px;

@mixin respond($breakpoints...) {
  @each $breakpoint in $breakpoints {
    @if $breakpoint == l {
      @media (min-width: calc($l - 1px)) {
        @content;
      }
    } @else if $breakpoint == m {
      @media (min-width: calc($s - 1px)) and (max-width: calc($l - 1px)) {
        @content;
      }
    } @else if $breakpoint == s {
      @media (min-width: calc($xs - 1px)) and (max-width: calc($s - 1px)) {
        @content;
      }
    } @else if $breakpoint == xs {
      @media (max-width: calc($xs - 1px)) {
        @content;
      }
    }
  }
}

@mixin respondMultiple($breakpoints...) {
  @each $breakpoint in $breakpoints {
    @include respond($breakpoint) {
      @content;
    }
  }
}
