@use "@/styles/colors";
@use "@/styles/utils";
@use "@/styles/breakpoints";

.hero {
  padding: 0 6%;

  @include breakpoints.respond(xs) {
    padding: 20px;
  }

  &__body,
  &__image-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  &__body {
    gap: 10dvh;
  }

  &__image {
    width: 85%;

    &:nth-child(2) {
      @include utils.imageSize(30%);

      @include breakpoints.respond(xs) {
        @include utils.imageSize(40%);
      }
    }
  }

  &__button {
    font-size: 1.5dvw !important;
    @include breakpoints.respond(xs) {
      font-size: 3dvw !important;
    }
    @include utils.textCorners(right top, right bottom, left top, left bottom);

    &-container {
      display: flex;
      gap: 13dvw;
    }

    &:before {
      background-size: 1dvw 1dvw;
      @include breakpoints.respond(xs) {
        background-size: 3dvw 3dvw;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      filter: blur(20px);
      background: transparentize(colors.$intense-white, 0.8);
    }
  }

  .section {
    &__content {
      justify-content: center;
    }
  }

  @include breakpoints.respond(m) {
    padding: 0 70px;

    .section {
      &__content {
        justify-content: space-between;
      }
    }

    &__body {
      max-width: 80%;
    }
  }

  @include breakpoints.respond(s) {
    padding: 0 12dvw;
  }
}
