@use "colors";
@use "breakpoints";

.button {
  font-family: Hanson;
  font-size: 30px;
  line-height: 31.23px;
  letter-spacing: 0.05em;
  outline: none;
  border: none;
  text-transform: uppercase;

  &-outline {
    padding: 1dvw;
    font-family: Hanson;
    font-size: 0.9dvw;
    font-weight: 700;
    line-height: 17px;
    color: colors.$intense-white;
    background: colors.$intense-black;
    border-radius: 1px;

    @include breakpoints.respond(m) {
      padding: 18px 17px 15px 17px;
      font-size: 14px;
    }
    @include breakpoints.respond(s) {
      padding: 1dvw;
      font-size: 1.1dvw;
    }
    @include breakpoints.respond(xs) {
      padding: 13px;
      font-size: 12px;
    }
  }
}
