@use "src/styles/colors";

.modal {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeInAnimation 1s;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
