@use "@/styles/fonts";
@use "@/styles/breakpoints";

.section__header,
.subsection__header {
  display: flex;
  flex-direction: column;

  &--bottom {
    order: 1;
  }

  &-title {
    padding: 0 20px;
    @include fonts.section-title;
    cursor: default;

    &__container {
      &--flex {
        display: flex;
        justify-content: space-between;
      }
    }

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }

    &--prev,
    &--next {
      opacity: 0.3;
      cursor: pointer;
    }
  }

  @include breakpoints.respond(xs) {
    display: none;
  }
}
