@use "@/styles/breakpoints";
@use "@/styles/colors";
@use "sass:math";

@mixin textCorners($positions...) {
  position: relative;
  padding: 1dvw;
  @include breakpoints.respond(xs) {
    padding: 10px;
  }

  $corner-map: (
    left top: "corner-1.svg",
    right top: "corner-2.svg",
    right bottom: "corner-3.svg",
    left bottom: "corner-4.svg"
  );

  $backgrounds: null;
  @each $position in $positions {
    @if map-has-key($corner-map, $position) {
      $image: map-get($corner-map, $position);
      $backgrounds: append($backgrounds, (url(../assets/images/#{$image}) $position), comma);
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $backgrounds;
    background-size: 2dvw 2dvw;
    background-repeat: no-repeat;
    z-index: -1;

    @include breakpoints.respond(m) {
      background-size: 30px 30px;
    }

    @include breakpoints.respond(s) {
      background-size: 10px 10px;
    }

    @include breakpoints.respond(xs) {
      background-size: 20px 20px;
    }
  }
}

@mixin positionElements($count) {
  $duration: 10s;

  @for $i from 1 through $count {
    &-item:nth-child(#{$i}) {
      animation: movePosition-#{$i} $duration linear infinite;
    }

    @keyframes movePosition-#{$i} {
      $total_steps: 10;
      $step: calc(360deg / $total_steps);
      $angle: random(360);

      @for $j from 0 through $total_steps {
        $angle: $angle + $step;
        #{percentage(calc($j / $total_steps))} {
          transform: translate3d(sin($angle) * 10px, cos($angle) * 10px, 0);
        }
      }
    }
  }
}

@mixin imageSize($w, $h: null) {
  max-width: $w;
  @if $h != null {
    max-height: $h;
  }
}

@mixin setBackground() {
  $sections: "hero", "oduwan", "bikes-models", "bikes-customisation", "process", "club", "contact";

  @each $section in $sections {
    &:has(.swiper-slide-active.#{$section}) {
      &::before {
        @if ($section == "hero") {
          @include breakpoints.respond(xs) {
            background-position-x: 70%;
          }
        } @else if ($section == "club") {
          mix-blend-mode: difference;
          z-index: 1;
          @include breakpoints.respond(xs) {
            mix-blend-mode: normal;
            z-index: 0;
          }
        }
      }

      &::before {
        background-image: url(../assets/images/backgrounds/#{$section}.jpg);
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    pointer-events: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.5s;
  }
}

@mixin dividerLine($width, $top, $withShadow: false) {
  &::after {
    content: "";
    display: block;
    position: relative;
    top: $top;
    left: 50%;
    transform: translateX(-50%);
    width: $width;
    height: 2px;
    background: colors.$intense-white;
    @if $withShadow != false {
      box-shadow: 0px 3px 3px 0px transparentize(colors.$intense-black, 0.7);
    }
  }
}
