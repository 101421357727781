@use "@/styles/colors";
@use "@/styles/breakpoints";

.divider {
  margin: 0;
  width: 0;
  height: 0;
  flex: 0;
  border: none;
  transition: all 1s cubic-bezier(1, 0.2, 0.75, 0.4);
  will-change: opacity;

  &--vertical {
    border-right: 1px solid colors.$intense-white;
    align-self: flex-end;
  }

  &--horizontal {
    border-top: 1px solid colors.$intense-white;
    margin-left: 0;
  }

  @include breakpoints.respond(xs) {
    display: none;
  }
}
